import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Card, Table, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RescheduleConfirmationDialogue from "./RescheduleConfirmationDialogue";
import { useAuth } from "../context/AuthContext";
import { ROLE_EMPLOYEE } from "../constants/AppConstants";
import CallIcon from "@mui/icons-material/Call";

const WalkInsTable = React.memo(function WalkInsTable({
  walkIns,
  showMoreClickHandler,
  disableShowMoreButtonForWalkIns,
  userId,
  employees = [],
  handleWalkInsFilterChange,
  updateTask,
  updateWalkIn,
  payloadForWalkIns
}) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    "Lead Id",
    "Lead Name",
    "Phone",
    "Walk / Call Date Time",
    "Rescheduled Date Time",
    "Walk-In / Call Status",
    "Note",
  ]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  //   reschedule confirmation dialogue states
  const [
    showRescheduleConfirmationDialogue,
    setShowRescheduleConfirmationDialogue,
  ] = useState(false);
  const [selectedWalkInStatus, setSelectedWalkInStatus] = useState(null);
  const [selectedWalkIn, setSelectedWalkIn] = useState(null);

  const getStatusDetails = (task_status) => {
    switch(task_status){
      case "Pending":
        return {
          status: "Pending",
          style: { fontWeight: "bold", color: "red" },
        }

      case "Upcoming":
        return {
          status: "Upcoming",
          style: { fontWeight: "bold", color: "blue" },
        }

      case "Completed":
        return {
          status: "Completed",
          style: { fontWeight: "bold", color: "green" },
        }

      case "Rescheduled":
        return {
          status: "Rescheduled",
          style: { fontWeight: "bold", color: "blue" },
        }

      case "Cancelled":
        return {
          status: "Cancelled",
          style: { fontWeight: "bold", color: "red" },
        }
    }
  };

  useEffect(() => {
    if (userId === null) {
      setColumns((prevColumns) => {
        if (!prevColumns.includes("Lead Owner")) {
          const newColumns = [...prevColumns];
          newColumns.splice(3, 0, "Lead Owner"); // Add at the 4th position
          return newColumns;
        }
        return prevColumns; // No changes if already present
      });
    } else {
      setColumns((prevColumns) =>
        prevColumns.filter((column) => column !== "Lead Owner")
      );
    }
  }, [userId]);

  function handleStatusChange(walk_in_id, walk_in_status, lead_name, lead_id, is_call) {
    let payload = { walk_in_id, walk_in_status, lead_name, lead_id };
    if (typeof updateTask === "function") {
      if (walk_in_status === "Rescheduled") {
        setSelectedWalkInStatus("Reschedule Walk In");
        setSelectedWalkIn({ walk_in_id, walk_in_status, lead_name, is_call });
        setShowRescheduleConfirmationDialogue(true);
      } else {
        updateTask(payload);
      }
    } else {
      console.error("updateTask is not a function");
    }
  }

  return (
    <>
      <Row className="my-4">
        <Col
          md={12}
          style={{
            backgroundColor: "white",
            padding: "0.5rem",
            borderRadius: "0.5rem",
          }}
        >
          <Card.Body>
            <Row className="my-2" style={{display:'flex', alignItems:'baseline'}}>
              <Col md={2}>
                <h1>Walk-Ins / Calls</h1>
              </Col>
              {employees.length > 0 && (
                <>
                  {/* filter by walk in status */}
                  <Col md={3}>  
                    {/* <div> */}
                    <select
                      name="walk_in_status"
                      value={payloadForWalkIns?.walk_in_status || ""}
                      className="form-control me-2"
                      onChange={(e) => handleWalkInsFilterChange(e)}
                      defaultValue=""
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Filter by Walk-In/Call Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Upcoming">Upcoming</option>
                      <option value="Completed">Completed</option>
                    </select>
                    {/* </div> */}
                  </Col>
                  {/* filter by employee name */}
                  <Col md={3}>
                    {/* <div> */}
                    <select
                      name="created_by"
                      value={payloadForWalkIns?.created_by || ""}
                      className="form-control me-2"
                      onChange={(e) => handleWalkInsFilterChange(e)}
                      defaultValue=""
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Filter by Employee Name</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.name}
                        </option>
                      ))}
                    </select>
                    {/* </div> */}
                  </Col>
                  <Col md={2}>
                    <Form.Control
                      type="date"
                      placeholder="Filter by Follow Up Date"
                      name="date"
                      value={payloadForWalkIns?.date || ""}
                      defaultValue=""
                      // value={filters.filter2}
                      onChange={(e) => handleWalkInsFilterChange(e)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                  <Col md={2}>
                    <Button
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        marginLeft: "1rem",
                      }}
                      onClick={()=>handleWalkInsFilterChange('reset')}
                    >
                      Reset Filters
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={column}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {walkIns?.map((walkIn, index) => {
                  const { status, style } = getStatusDetails(walkIn.walk_in_status);
                  return (
                    <tr
                      key={walkIn.id}
                      onDoubleClick={() =>
                        navigate(`/lead-details-emp/${walkIn.lead_id}`, {
                          state: {
                            showVerificationStatusChangeSection:
                              user.user.role !== ROLE_EMPLOYEE &&
                              walkIn.lead.lead_status === "Verification 1",
                            showApplicationStatusChangeSection:
                              user.user.role !== ROLE_EMPLOYEE &&
                              walkIn.lead.verification_status ===
                                "Scheduled For Walk-In",
                            showLeadStatusChangeSection:
                              user.user.role === ROLE_EMPLOYEE,
                            leadBucket:
                              walkIn.lead.lead_status === "Verification 1"
                                ? "Verification 1"
                                : walkIn.lead.verification_status ===
                                  "Scheduled For Walk-In"
                                ? "WalkIns"
                                : "Leads",
                            fromPage:
                              user.user.role === ROLE_EMPLOYEE
                                ? "admin-dashboard"
                                : "employee-dashboard",
                          },
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{walkIn.lead_id}</td>
                      <td>{walkIn.lead.name}</td>
                      <td>{walkIn.lead.phone}</td>
                      {userId === null && (
                        <td>
                          {walkIn.lead.LeadAssignments[0]?.AssignedTo?.name}
                        </td>
                      )}

                      {/* walk in / call date time column */}
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "none",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "24px",
                          }}
                        >
                          {" "}
                          {/* Fixed height */}
                          {moment(walkIn.walk_in_date_time)
                            .utcOffset(330)
                            .format("DD MMM, YYYY hh:mm A")}
                          {walkIn.is_call && (
                            <div style={{ marginLeft: "8px", height: "100%" }}>
                              <CallIcon
                                color="success"
                                style={{ scale: "0.7" }}
                              />
                            </div>
                          )}
                        </div>
                      </td>

                      <td style={{ fontWeight: "bold" }}>
                        {walkIn.is_rescheduled
                          ? moment(walkIn.rescheduled_date_time)
                              .utcOffset(330)
                              .format("DD MMM, YYYY hh:mm A")
                          : ""}
                      </td>
                      <td style={style}>
                        {employees.length <= 0 ? (
                          <select
                            value={walkIn.walk_in_status || "Upcoming"} // Fallback to "Upcoming" if undefined or invalid
                            onChange={(e) =>
                              handleStatusChange(
                                walkIn.id,
                                e.target.value,
                                walkIn.lead.name,
                                walkIn.lead_id,
                                walkIn.is_call
                              )
                            }
                            onClick={(e) => (e.target.value = "")}
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                e.target.value =
                                  walkIn.walk_in_status || "Upcoming";
                              }
                            }}
                            style={{
                              ...style,
                              border: "none",
                              backgroundColor: "transparent",
                              outline: "none",
                              fontWeight: "bold",
                              fontSize: "inherit",
                              cursor: "pointer",
                            }}
                          >
                            {walkIn.walk_in_status !== "Rescheduled" && (
                              <option
                                value="Upcoming"
                                style={{ color: "orange", cursor: "pointer" }}
                                disabled
                              >
                                Upcoming
                              </option>
                            )}
                            <option
                              value="Rescheduled"
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              Rescheduled
                            </option>
                            <option
                              value="Completed"
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              Completed
                            </option>
                            <option
                              value="Cancelled"
                              style={{ color: "red", cursor: "pointer", fontWeight:'bold' }}
                            >
                              Cancel
                            </option>
                            <option
                              value="Pending"
                              style={{ color: "red", cursor: "pointer", fontWeight:'bold' }}
                              disabled
                            >
                              Pending
                            </option>
                          </select>
                        ) : (
                          <span style={style}>{status}</span>
                        )}
                      </td>
                      <td>{walkIn.note}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Button
              onClick={() => showMoreClickHandler("walk_ins")}
              disabled={disableShowMoreButtonForWalkIns}
            >
              Show More...
            </Button>
          </Card.Body>
        </Col>
      </Row>
      {showRescheduleConfirmationDialogue && (
        <RescheduleConfirmationDialogue
          open={showRescheduleConfirmationDialogue}
          handleClose={() => setShowRescheduleConfirmationDialogue(false)}
          walkIn={selectedWalkIn}
          updateLeadStatus={(id, newStatus) => updateWalkIn(id, newStatus)}
          statusToBeUpdated={selectedWalkInStatus}
        />
      )}
    </>
  );
});

export default WalkInsTable;
