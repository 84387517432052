import axios from "axios";

export async function getTasksByLeadId(leadId){
    return axios.get(`/api/activities/get-activites-by-lead-id/${leadId}`)
}

export async function updateTaskByTaskId(payload, taskId){
    return axios.put(`/task/updateTaskByTaskId/${taskId}`,payload)
}

export async function getAllActivities(params){
    return axios.get('/api/activities/get-all-activities',{params:params})
}

export async function getActivitiesByLeadId(leadId){
    return axios.get(`/api/activities/get-activites-by-lead-id/${leadId}`)
}