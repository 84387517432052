import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  updateApplicationStatus,
  updateLeadStatus,
  updateVerificationStatus,
} from "../../services/admin/leads-services";
import { Col, Form, Row } from "react-bootstrap";
// Create custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    maxWidth: "none !important", // Removes maxWidth
    maxHeight: "none !important", // Removes maxHeight
  },
  dialogContent: {
    padding: theme.spacing(2),
    display: "flex !important",
    flexDirection: "column !important",
  },
  dialogActions: {
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute !important",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    display:'flex !important',
    justifyContent:'space-between !important',
    alignItems:'baseline !important'
  },
  secondaryButton: {
    border: "1px solid black !important",
    color: "black !important",
    "&:hover": {
      backgroundColor: "gray !important",
      color: "white !important",
    },
  },
  primaryButton: {
    border: "1px solid black !important",
    color: "white !important",
    backgroundColor: "#fd7e14 !important",
    "&:hover": {
      backgroundColor: "#fd7e14 !important",
      color: "white !important",
    },
  },
}));

// Create a custom dialog component
const ConfirmationDialogue = React.memo(function ConfirmationDialogue({
  open,
  handleClose,
  payload,
  updateLeadState,
  apiName,
  title,
  showRejectionReasonInput = false,
  setPayloadForUpdateApplicationStatus,
  setPayloadForUpdateVerificationStatus,
}) {
  console.log("payload in confirmation dialogue", payload, title);

  const classes = useStyles();
  const [helperMessage, setHelperMessage] = useState(null);
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const [apiResponseType, setApiResponseType] = useState("inProgress");
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [
    shouldActivityDialogueCloseOnClickOfOutside,
    setShouldActivityDialogueCloseOnClickOfOutside,
  ] = useState(true);
  const [rejectionReason, setRejectionReason] = useState(
    payload.rejection_reason || ""
  );
  const [rejectionReasonError, setRejectionReasonError] = useState(null);
  const [noteType, setNoteType] = useState(getFieldName(apiName))

  useEffect(()=>{
    setNoteType(getFieldName(apiName))
  },[apiName])

  async function handleUpdate(actionFrom) {
    if (
      apiName === "updateApplicationStatus" && 
      payload.application_status === "Rejected" && 
      (!payload.rejection_reason || payload.rejection_reason.trim() === "")
    ) {
      setRejectionReasonError("Rejection Reason is Required!");
      return;
    }
  
    if (
      apiName === "updateVerificationStatus" && 
      payload.verification_status === "Rejected" && 
      (!payload.rejection_reason || payload.rejection_reason.trim() === "")
    ) {
      setRejectionReasonError("Rejection Reason is Required!");
      return;
    }
    setDisableCloseButton(true);
    setApiResponseType("inProgress");
    setShouldActivityDialogueCloseOnClickOfOutside(false);
    setDisableSubmitButton(true);
    setDisableCancelButton(true);
    if (actionFrom === "retry") {
      setHelperMessage("Retrying...");
    } else {
      switch (apiName) {
        case "updateVerificationStatus":
          setHelperMessage("Please wait updating verification status...");
          break;

        case "updateApplicationStatus":
          setHelperMessage("Please wait updating application status...");
          break;

        case "updateLeadStatus":
          setHelperMessage("Please wait updating lead status...");
          break;
      }
    }

    try {
      switch (apiName) {
        case "updateVerificationStatus":
          console.log("payload for update verification status =", payload);

          const responseFromVerificationStatusApi =
            await updateVerificationStatus(payload);
          setHelperMessage("Verification status updated successfully");
          setDisableCancelButton(false);
          setDisableCloseButton(false);
          setApiResponseType("success");
          setShowCloseButton(true);
          updateLeadState(responseFromVerificationStatusApi.data.data);
          setShouldActivityDialogueCloseOnClickOfOutside(true);
          resetStates();
          return;

        case "updateApplicationStatus":
          console.log("payload application status = ", payload);
          const responseFromApplicationStatusApi =
            await updateApplicationStatus(payload);
          setHelperMessage("Application status updated successfully");
          setDisableCancelButton(false);
          setDisableCloseButton(false);
          setApiResponseType("success");
          setShowCloseButton(true);
          updateLeadState(responseFromApplicationStatusApi.data.data);
          setShouldActivityDialogueCloseOnClickOfOutside(true);
          resetStates();
          return;

        case "updateLeadStatus":
          const responseFromLeadStatusApi = await updateLeadStatus(payload);
          setHelperMessage("Lead status updated successfully");
          setDisableCancelButton(false);
          setDisableCloseButton(false);
          setApiResponseType("success");
          setShowCloseButton(true);
          updateLeadState(responseFromLeadStatusApi.data.data);
          setShouldActivityDialogueCloseOnClickOfOutside(true);
          resetStates();
          return;

        case "assignLeadsToEmployee":
          updateLeadState(payload);
          return;
      }
    } catch (error) {
      switch (apiName) {
        case "updateVerificationStatus":
          console.log(error);
          setHelperMessage("Failed to update verification status !");
          setDisableCloseButton(false);
          setShowCloseButton(true);
          setApiResponseType("error");
          setDisableCancelButton(true);
          setShouldActivityDialogueCloseOnClickOfOutside(true);
          break;

        case "updateApplicationStatus":
          console.log(error);
          setHelperMessage("Failed to update application status !");
          setDisableCloseButton(false);
          setShowCloseButton(true);
          setApiResponseType("error");
          setDisableCancelButton(true);
          setShouldActivityDialogueCloseOnClickOfOutside(true);
          break;

        case "updateLeadStatus":
          console.log(error);
          setHelperMessage("Failed to update lead status !");
          setDisableCloseButton(false);
          setShowCloseButton(true);
          setApiResponseType("error");
          setDisableCancelButton(true);
          setShouldActivityDialogueCloseOnClickOfOutside(true);
          break;
      }
    }
  }

  function resetStates() {}
  function getFieldName(apiName){
    switch(apiName){
      case "updateVerificationStatus":
        return 'verification_status_note';

      case "updateApplicationStatus":
        return 'application_status_note';
    }
  }

  function getPlaceHolder(apiName){
    switch(apiName){
      case "updateVerificationStatus":
        return "Write a note about the verification status";

      case "updateApplicationStatus":
        return "Write a note about the application status";
    }
  }

  return (
    <Dialog
      onClose={shouldActivityDialogueCloseOnClickOfOutside && handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: classes.dialogRoot }}
    >
      <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
        { apiName === "assignLeadsToEmployee" ? title?.split("-")[0] : title }
        <IconButton
          aria-label="close"
          onClick={
            shouldActivityDialogueCloseOnClickOfOutside ? handleClose : () => {}
          }
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {/* content container */}
        <Grid2
          style={{
            height: "max-content",
            width: "50vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxHeight: "75vh",
          }}
        >
          {helperMessage ? (
            <>
              {/* helper message UI code */}
              <Grid2
                style={{
                  minHeight: "50vh",
                  minWidth: "50vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {apiResponseType == "success" ? (
                  <TaskAltIcon style={{ color: "green" }} fontSize="large" />
                ) : apiResponseType == "error" ? (
                  <ErrorOutlineIcon style={{ color: "red" }} fontSize="large" />
                ) : (
                  <CircularProgress style={{ color: "rgb(127, 12, 134)" }} />
                )}
                {helperMessage}
              </Grid2>
            </>
          ) : (
            <>
              {/* actual content UI code */}
              {/* status section */}
              <Grid2
                style={{
                  display: "flex",
                  flexDirection: "column", // Align items in a column layout
                  width: "100%", // Ensure the grid takes full width
                  padding: "0rem 0.2rem",
                  margin: "0.6rem 0rem",
                }}
              >
                {(() => {
                  switch (apiName) {
                    case "updateVerificationStatus":
                      return (
                        <h4 style={{ textAlign: "center" }}>
                          {`Are you really sure you want to update the Verification Status to "${payload.verification_status}"?`}
                        </h4>
                      );

                    case "updateApplicationStatus":
                      return (
                        <h4 style={{ textAlign: "center" }}>
                          {`Are you really sure you want to update the Application Status to "${payload.application_status}"?`}
                        </h4>
                      );

                    case "updateLeadStatus":
                      return (
                        <h4 style={{ textAlign: "center" }}>
                          {`Are you really sure you want to update the Lead Status to "${payload.lead_status}"?`}
                        </h4>
                      );

                      case "assignLeadsToEmployee":
                        const highlightText = (text) => {
                          let updatedText = text;
                      
                          // Highlight 'reassign' part in red
                          if (updatedText.includes("reassign")) {
                            updatedText = updatedText.replace(
                              /reassign \d+ lead\(s\)/g, 
                              (match) => `<span style="color: red">${match}</span>`
                            );
                          }
                      
                          // Highlight 'assign' part in green
                          if (updatedText.includes("assign")) {
                            updatedText = updatedText.replace(
                              /assign \d+ fresh lead\(s\)/g, 
                              (match) => `<span style="color: green">${match}</span>`
                            );
                          }

                          let empName = updatedText.split("to").pop().trim();

                          // Step 4: Add employee name in bold
                            updatedText = updatedText.replace(
                                          empName, 
                                          `<strong>${empName}</strong>`
                                        );
                          return updatedText;
                        };
                        return <h4 style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: highlightText(title.split("-")[1]) }} />;
                  }
                })()}

                {/* Rejection Reason section - visible only for updateApplicationStatus */}
                {(() => {
                  let showRejectionReason = false;
                  let updatePayload;

                  switch (apiName) {
                    case "updateApplicationStatus":
                      showRejectionReason =
                        payload.application_status === "Rejected";
                      updatePayload = (key, value) =>
                        setPayloadForUpdateApplicationStatus((prev) => ({
                          ...prev,
                          [key]: value,
                        }));
                      break;
                    case "updateVerificationStatus":
                      showRejectionReason =
                        payload.verification_status === "Rejected";
                      updatePayload = (key, value) =>
                        setPayloadForUpdateVerificationStatus((prev) => ({
                          ...prev,
                          [key]: value,
                        }));
                      break;
                    // Add more cases as needed
                    default:
                      showRejectionReason = false;
                      updatePayload = () => {}; // No-op for default
                  }

                  return (
                    showRejectionReason && (
                      <Row className="align-items-center">
                        <Col xs={12} style={{ padding: "0rem 0.2rem" }}>
                          <Form.Group controlId="rejectionReason">
                            <Form.Label>Rejection Reason</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="rejection_reason"
                              value={payload.rejection_reason || ""}
                              onChange={(e) =>
                                updatePayload(
                                  "rejection_reason",
                                  e.target.value
                                )
                              }
                              placeholder="Provide the reason for rejection"
                              isInvalid={!!rejectionReasonError}
                            />
                            <Form.Control.Feedback type="invalid">
                              {rejectionReasonError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    )
                  );
                })()}

                {/* note for verification status and application status */}
                {(() => {
                  let showNoteField = false;
                  let updatePayload;

                  switch (apiName) {
                    case "updateApplicationStatus":
                      showNoteField =
                        payload.application_status !== "Rejected";
                      updatePayload = (key, value) =>
                        setPayloadForUpdateApplicationStatus((prev) => ({
                          ...prev,
                          [key]: value,
                        }));
                      break;
                    case "updateVerificationStatus":
                      showNoteField =
                        payload.verification_status !== "Rejected";
                      updatePayload = (key, value) =>
                        setPayloadForUpdateVerificationStatus((prev) => ({
                          ...prev,
                          [key]: value,
                        }));
                      break;
                    // Add more cases as needed
                    default:
                      showNoteField = false;
                      updatePayload = () => {}; // No-op for default
                  }

                  return (
                    showNoteField && (
                      <Row className="align-items-center">
                        <Col xs={12} style={{ padding: "0rem 0.2rem" }}>
                          <Form.Group controlId={noteType}>
                            <Form.Label>Note</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name={noteType}
                              value={payload[noteType] || ""}
                              onChange={(e) =>
                                updatePayload(
                                  noteType,
                                  e.target.value
                                )
                              }
                              placeholder={getPlaceHolder(apiName)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )
                  );
                })()}
              </Grid2>
            </>
          )}
        </Grid2>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {showCloseButton ? (
          <>
            <Button
              autoFocus
              onClick={
                shouldActivityDialogueCloseOnClickOfOutside && handleClose
              }
              className={classes.secondaryButton}
              disabled={disableCloseButton}
            >
              Close
            </Button>
            {apiResponseType === "error" && (
              <Button
                autoFocus
                className={classes.primaryButton}
                onClick={() => handleUpdate("retry")}
              >
                Retry
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              autoFocus
              onClick={() => {
                resetStates();
                handleClose();
              }}
              className={classes.secondaryButton}
              disabled={disableCancelButton}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={() => handleUpdate("submit")}
              className={classes.primaryButton}
              disabled={disableSubmitButton}
            >
              {apiName === "assignLeadsToEmployee" ? 'Confirm' : 'Update'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmationDialogue;
