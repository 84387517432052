import React, { useEffect, useState } from "react";
import { ROLE_EMPLOYEE, ROLE_MANAGER } from "../constants/AppConstants";
import ManagerSidebar from "./Sidebar/ManagerSidebar";
import AdminSidebar from "./Sidebar/AdminSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { updateTaskStatus } from "../services/tasks-services";
import CommonSnackbar from "./common/CommonSnackbar";
import { useAuth } from "../context/AuthContext";
import CallIcon from "@mui/icons-material/Call";

const RecentTasks = React.memo(function RecentTasks({
  tasks,
  showMoreClickHandler,
  disableShowMoreButtonForRecentTasks,
  userId,
  employees = [],
  handleRecentTasksFilterChange,
  updateTask,
  payloadForRecentTasks
}) {
  const {user} = useAuth()
  const navigate = useNavigate();
  console.log("user id = ", userId);
  const [columns, setColumns] = useState([
    "Lead Id",
    "Lead Name",
    "Phone",
    // "Lead Owner",
    "Task",
    "Note",
    "Task Date",
    "Task Status",
  ]);
  
  useEffect(() => {
    if (userId === null) {
      setColumns((prevColumns) => {
        if (!prevColumns.includes("Lead Owner")) {
          const newColumns = [...prevColumns];
          newColumns.splice(3, 0, "Lead Owner"); // Add at the 4th position
          return newColumns;
        }
        return prevColumns; // No changes if already present
      });
    } else {
      setColumns((prevColumns) =>
        prevColumns.filter((column) => column !== "Lead Owner")
      );
    }
  }, [userId]);

  const getStatusDetails = (task_status) => {
    switch(task_status){
      case "Pending":
        return {
          status: "Pending",
          style: { fontWeight: "bold", color: "red" },
        }

      case "Upcoming":
        return {
          status: "Upcoming",
          style: { fontWeight: "bold", color: "blue" },
        }

      case "Completed":
        return {
          status: "Completed",
          style: { fontWeight: "bold", color: "green" },
        }
    }
  };

  function handleStatusChange(activity_id, task_status, task_type, lead_id, lead_name) {
    if (typeof updateTask === "function") {
      updateTask({ activity_id, task_status, task_type, lead_id, lead_name });
    } else {
      console.error("updateTask is not a function");
    }
  }

  return (
    <>
      <Row>
        <Col
          md={12}
          style={{
            backgroundColor: "white",
            padding: "0.5rem",
            borderRadius: "0.5rem",
          }}
        >
          <Card.Body>
            <Row className="my-2" style={{alignItems:'baseline'}}>
              <Col md={2}>
                <h1>Recent Tasks</h1>
              </Col>
              {employees.length > 0 && (
                <>
                {/* task type */}
                <Col md={2}>
                    {/* <div> */}
                    <select
                      name="task_type"
                      value={payloadForRecentTasks?.task_type || ""}
                      className="form-control me-2"
                      onChange={(e) => handleRecentTasksFilterChange(e)}
                      defaultValue=""
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Filter by Task Type</option>
                      <option value="Follow Up">Follow Up</option>
                      <option value="Call Back">Call Back</option>
                      <option value="Scheduled Call With Manager">Scheduled Call With Manager</option>
                    </select>
                    {/* </div> */}
                  </Col>
                  {/* task status */}
                  <Col md={2}>
                    {/* <div> */}
                    <select
                      name="task_status"
                      value={payloadForRecentTasks?.task_status || ""}
                      className="form-control me-2"
                      onChange={(e) => handleRecentTasksFilterChange(e)}
                      defaultValue=""
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Filter by Task Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Upcoming">Upcoming</option>
                      <option value="Completed">Completed</option>
                    </select>
                    {/* </div> */}
                  </Col>

                  <Col md={2}>
                    {/* <div> */}
                    <select
                      name="created_by"
                      value={payloadForRecentTasks?.created_by || ""}
                      className="form-control me-2"
                      onChange={(e) => handleRecentTasksFilterChange(e)}
                      defaultValue=""
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Filter by Employee Name</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.name}
                        </option>
                      ))}
                    </select>
                    {/* </div> */}
                  </Col>
                  <Col md={2}>
                    <Form.Control
                      type="date"
                      placeholder="Filter by Follow Up Date"
                      name="follow_up"
                      value={payloadForRecentTasks?.follow_up || ""}
                      defaultValue=""
                      // value={filters.filter2}
                      onChange={(e) => handleRecentTasksFilterChange(e)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                  <Col md={2}>
                    <Button
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        marginLeft: "1rem",
                      }}
                      onClick={()=>handleRecentTasksFilterChange('reset')}
                    >
                      Reset Filters
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={column}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tasks?.map((task, index) => {
                  const { status, style } = getStatusDetails(task.task_status);
                  return (
                    <tr
                      key={task.id}
                      onDoubleClick={() =>
                        navigate(`/lead-details-emp/${task.lead_id}`,{
                          state:{
                            showVerificationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE && task.Lead.lead_status === "Verification 1",
                            showApplicationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE && task.Lead.verification_status === "Scheduled For Walk-In",
                            showLeadStatusChangeSection: user.user.role === ROLE_EMPLOYEE,
                            leadBucket: task.Lead.lead_status === "Verification 1" ? 'Verification 1' : task.Lead.verification_status === "Scheduled For Walk-In" ? "WalkIns" : 'Leads',
                            fromPage: user.user.role === ROLE_EMPLOYEE ? 'employee-dashboard' : 'admin-dashboard'
                          }
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{task.lead_id}</td>
                      <td>{task.Lead.name}</td>
                      <td>{task.Lead.phone}</td>
                      {userId === null && (
                        <td>{task.Lead.LeadAssignments[0].AssignedTo.name}</td>
                      )}
                      <td>{task.activity_status}</td>
                      <td>{task.description}</td>

                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "none",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "24px",
                          }}
                        >
                          {" "}
                          {/* Fixed height */}
                          {moment(task.follow_up)
                            .utcOffset(330)
                            .format("DD MMM, YYYY hh:mm A")}
                          {task.activity_status === "Scheduled Call With Manager" && (
                            <div style={{ marginLeft: "8px", height: "100%" }}>
                              <CallIcon
                                color="success"
                                style={{ scale: "0.7" }}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                      <td style={style}>
                        {employees.length <= 0 ? (
                          <select
                            value={task.task_status}
                            onChange={(e) =>
                              handleStatusChange(task.id, e.target.value, task.activity_status, task.lead_id, task.Lead.name)
                            }
                            style={{
                              ...style, // Apply the same style (color, boldness)
                              border: "none",
                              backgroundColor: "transparent",
                              outline: "none",
                              fontWeight: "bold",
                              fontSize: "inherit",
                              cursor: "pointer",
                            }}
                          >
                            <option
                              value="Upcoming"
                              style={{ color: "orange", cursor: "pointer" }}
                            >
                              Upcoming
                            </option>
                            <option
                              value="Pending"
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              Pending
                            </option>
                            <option
                              value="Completed"
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              Completed
                            </option>
                          </select>
                        ) : (
                          <span style={style}>{status}</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Button
              onClick={() => showMoreClickHandler("tasks")}
              disabled={disableShowMoreButtonForRecentTasks}
            >
              Show More...
            </Button>
          </Card.Body>
        </Col>
      </Row>
    </>
  );
});

export default RecentTasks;
