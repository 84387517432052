import React from "react";
import { Form } from "react-bootstrap";

// Common Select Component
export default function CommonSelect({
  options = [],
  value = "",
  onChange,
  label = "",
  helperText = "",
  displayEmpty = false,
  fullWidth = false,
  required = false,
  id = "common-select",
  style = {},
}) {
  return (
    <Form.Group
      controlId={id}
      style={{ minWidth: fullWidth ? "100%" : "120px", ...style }}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as="select"
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
      >
        {displayEmpty && <option value="">Select an option</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </Form.Control>
      {helperText && <Form.Text className="text-muted">{helperText}</Form.Text>}
    </Form.Group>
  );
}
