import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import {
  deleteLeadDocument,
  getLeadDocumentsByLeadId,
  uploadFile,
} from "../services/lead-document-services";
import { useLocation } from "react-router-dom";
import CommonSnackbar from "./common/CommonSnackbar";
import moment from "moment";

const LeadDocuments = React.memo(function LeadDocuments({
  leadId,
  leadName,
  disableUpload,
  userId,
  setLeadDocuments={}
}) {
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const showOtherDocsUploadButton =
    location?.state?.showApplicationStatusChangeSection &&
    location?.state?.are12DocumentsCollected;

  const documentTypes = [
    { id: "payslip", label: "Payslip", maxFiles: 3, backgroundColor: "blue" },
    {
      id: "creditBureau",
      label: "Credit Bureau",
      maxFiles: 1,
      backgroundColor: "green",
    },
    {
      id: "otherDocs",
      label: "Other Documents",
      maxFiles: 12,
      backgroundColor: "orange",
    },
  ];
  // snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  useEffect(() => {
    console.log("lead documents mounted");

    fetchLeadDocuments(leadId);
  }, []);

  const handleFileUpload = async (e, type, document_type) => {
    setSnackbarType("info");
    setSnackbarMessage("Uploading File...");
    setOpenSnackbar(true);
    const uploadedFiles = Array.from(e.target.files);
    if (uploadedFiles.length === 0) return;

    const currentFiles = files.filter((f) => f.document_type !== type);
    const maxFiles = documentTypes.find((doc) => doc.id === type).maxFiles;

    const formData = new FormData();
    uploadedFiles.forEach((file) => formData.append("file", file));
    formData.append("type", type);
    formData.append("lead_id", leadId);
    formData.append("document_type", document_type);
    formData.append("lead_name", leadName);
    formData.append("user_id", userId)

    try {
      console.log('form data = ', formData);
      
      const response = await uploadFile(formData);

      if (response.data.status === "error") {
        throw new Error("Failed to upload files");
      }

      const result = response.data;
      const newUploadedFiles = [
        {
          id: result.data.dbData.id || Math.random(), // Fallback to temporary ID
          lead_id: result.data.dbData.lead_id,
          document_name: result.data.dbData.document_name,
          document_type: type,
          document_url: result.data.dbData.document_url,
          status: result.data.dbData.status,
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
        },
      ];

      const newFiles = [
        ...currentFiles,
        ...files
          .filter((f) => f.document_type === type)
          .concat(newUploadedFiles)
          .slice(0, maxFiles),
      ];

      setFiles(newFiles);
      setLeadDocuments(newFiles)
      setSnackbarType("success");
      setSnackbarMessage(response.data.message);
      setTimeout(() => setOpenSnackbar(false), 2000);
    } catch (error) {
      console.error("File upload error:", error);
      setSnackbarType("error");
      setSnackbarMessage("Failed to upload file !");
      // setTimeout(()=>setOpenSnackbar(false),2000)
    }
  };

  const handleFileRemove = async (fileId,file) => {
    setSnackbarType("info");
    setSnackbarMessage("Deleting File...");
    setOpenSnackbar(true);
    try {
      const response = await deleteLeadDocument({ lead_document_id: fileId,file, userId, lead_name:leadName});
      setFiles(files.filter((file) => file.id !== fileId));
      setSnackbarType("success");
      setSnackbarMessage(response.data.message);
      setTimeout(() => setOpenSnackbar(false), 2000);
    } catch (error) {
      console.error("File delete error:", error);
      setSnackbarType("error");
      setSnackbarMessage("Failed to upload file !");
    }
  };

  const renderUploadButtons = () => {
    return documentTypes.map((docType) => {
      const currentFilesCount = files.filter(
        (file) => file.document_type === docType.id
      ).length;

      return (
        <Col md={4} key={docType.id} className="mb-3 text-center">
          {docType.id === "otherDocs" && !showOtherDocsUploadButton ? null : (
            <>
              <Button
                size="lg"
                onClick={() =>
                  document.getElementById(`upload-${docType.id}`).click()
                }
                disabled={
                  currentFilesCount >= docType.maxFiles || disableUpload
                }
                style={{
                  backgroundColor: docType.backgroundColor,
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Upload {docType.label}
              </Button>
              <p className="mt-2">{`Uploaded: ${currentFilesCount}/${docType.maxFiles}`}</p>
              <input
                type="file"
                id={`upload-${docType.id}`}
                accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx"
                multiple={docType.maxFiles > 1}
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, docType.id, docType.id)}
              />
            </>
          )}
        </Col>
      );
    });
  };

  const renderFilesTable = () => {
    if (files.length === 0) {
      return <p className="text-center mt-3">No files uploaded yet.</p>;
    }

    return (
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>File Name</th>
            <th>Document Type</th>
            <th>Actions</th>
            <th>Uploaded At</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={file.id}>
              <td>{index + 1}</td>
              <td>{file.document_name}</td>
              <td>{file.document_type}</td>{" "}
              {/* Assuming `file.type` holds the document type */}
              <td style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button
                  size="sm"
                  onClick={() => window.open(file.document_url, "_blank")}
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  Download
                </Button>
                <Button
                  size="sm"
                  onClick={() => handleFileRemove(file.id, file)}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Remove
                </Button>
              </td>
              <td>
                {moment(file.createdAt)
                  .utcOffset(330)
                  .format("DD MMM, YYYY hh:mm:ss A")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  async function fetchLeadDocuments(leadId) {
    try {
      const response = await getLeadDocumentsByLeadId({ lead_id: leadId });
      const normalizedFiles = response.data.data.map((doc) => ({
        id: doc.id,
        lead_id: doc.lead_id,
        document_name: doc.document_name,
        document_type: doc.document_type,
        document_url: doc.document_url,
        status: doc.status,
        createdAt:doc.createdAt
      }));
      setFiles([...normalizedFiles]);
      setLeadDocuments([...normalizedFiles])
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Card className="p-3 mb-3">
        <h4>Upload Documents</h4>
        <Row>{renderUploadButtons()}</Row>
        {renderFilesTable()}
      </Card>
      {openSnackbar && (
        <CommonSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          type={snackbarType}
        />
      )}
    </>
  );
});

export default LeadDocuments;
