import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import "./AdminDashboard.css";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import { getTotalLeadsCount } from "../../services/admin/leads-services";
import { getAllActivities } from "../../services/admin/tasks-services";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import { ROLE_ADMIN, ROLE_MANAGER } from "../../constants/AppConstants";
import EmployeeSidebar from "../../components/Sidebar/EmployeeSidebar";
import ManagerSidebar from "../../components/Sidebar/ManagerSidebar";
import { getAllTasks } from "../../services/tasks-services";
import { useNavigate } from "react-router-dom";
import CommonBackdrop from "../../components/common/CommonBackdrop";
import RecentTasks from "../../components/RecentTasks";
import { getUsers } from "../../services/admin/users-services";
import AnalyticsDashboard from "../../components/AnalyticsDashboard";
import WalkInsTable from "../../components/WalkInsTable";
import { getWalkIns, getWalkInsCount } from "../../services/walk-in-services";
import RecentLeadActivities from "../../components/RecentLeadActivities";
import { debounce } from "../../utilities/helper-functions";
import RecentLeadActivityLogs from "../../components/RecentLeadActivityLogs";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { role, user, users } = useAuth();
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [leadsToday, setLeadsToday] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const [disableShowMoreButton, setDisableShowMoreButton] = useState(false);
  const [payloadForRecentActivity, setPayloadForRecentActivity] = useState({
    page: 1,
    pageSize: Math.floor(window.innerHeight / 40),
  });
  const [employees, setEmployees] = useState([]);
  const [walkinsTotal, setWalkinsTotal] = useState(null)
  const [walkinsToday, setWalkinsToday] = useState(null)

  // tasks states
  const [tasks, setTasks] = useState([]);
  const [payloadForRecentTasks, setPayloadForRecentTasks] = useState({
    page: 1,
    pageSize: Math.floor(window.innerHeight / 40),
  });
  const [
    disableShowMoreButtonForRecentTasks,
    setDisableShowMoreButtonForRecentTasks,
  ] = useState(false);

  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  // walk-ins states
  const [walkIns, setWalkIns] = useState([]);
  const [payloadForWalkIns, setPayloadForWalkIns] = useState({
    page: 1,
    pageSize: Math.floor(window.innerHeight / 40),
  });
  const [disableShowMoreButtonForWalkIns, setDisableShowMoreButtonForWalkIns] =
    useState(false);

  // lead activities states
  const [disableShowMoreButtonForLeadActivities, setDisableShowMoreButtonForLeadActivities] = useState(false)

  useEffect(() => {
    setBackdropMessage("Loading...");
    setOpenBackdrop(true);
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchTotalLeads(),
          fetchLeadsToday({ today: true }),
          fetchRecentActivities({ ...payloadForRecentActivity }),
          fetchRecentTasks({ ...payloadForRecentTasks }),
          fetchWalkIns({ ...payloadForWalkIns }),
          fetchWalkInsCount({}),
          fetchEmployees(),
        ]);
        setOpenBackdrop(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setBackdropMessage(error.message);
        setResponseType("FAILED");
        setShouldBackdropCloseOnClickOfOutside(true);
      }
    };

    fetchData();
  }, []);

  const debouncedFetchLeadActivities = useCallback(
    debounce((params)=> fetchRecentActivities(params, true), 700),
    []
  )

  function handleRecentTasksFilterChange(e) {
    if(e === 'reset'){
      let initialFilters = {
        page: 1,
        pageSize: Math.floor(window.innerHeight / 40),
      }
      setPayloadForRecentTasks(initialFilters)
      fetchRecentTasks(initialFilters, true)
      return
    }
    const { name, value } = e.target;
    const updatedFilters = { ...payloadForRecentTasks, [name]: value, page:1 };
    setPayloadForRecentTasks(updatedFilters);
    fetchRecentTasks(updatedFilters, true);
  }

  function handleWalkInsFilterChange(e) {
    if(e === 'reset'){
      let initialFilters = {
        page: 1,
        pageSize: Math.floor(window.innerHeight / 40),
      }
      setPayloadForWalkIns(initialFilters)
      fetchWalkIns(initialFilters, true)
      return
    }
    const { name, value } = e.target;
    const updatedFilters = { ...payloadForWalkIns, [name]: value };
    setPayloadForWalkIns(updatedFilters);
    fetchWalkIns(updatedFilters, true);
  }

  const todayFormatted = new Date().toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  if (error) {
    return <p>{error}</p>;
  }

  function showMoreClickHandler(clickedOn) {
    switch (clickedOn) {
      case "lead_activities":
        setPayloadForRecentActivity((prevState) => {
          console.log("prev acitivity = ", prevState);
          const updatedState = { ...prevState, page: prevState.page + 1 };
          fetchRecentActivities(updatedState); // pass updated state to API
          return updatedState;
        });
        return;

      case "tasks":
        setPayloadForRecentTasks((prevState) => {
          console.log("prev acitivity = ", prevState);
          const updatedState = { ...prevState, page: prevState.page + 1 };
          fetchRecentTasks(updatedState); // pass updated state to API
          return updatedState;
        });

      case "walk_ins":
        setPayloadForWalkIns((prevState) => {
          const updatedState = { ...prevState, page: prevState.page + 1 };
          fetchWalkIns(updatedState); // pass updated state to API
          return updatedState;
        });

      default:
        console.warn("Unknown buttonClickedFor:", clickedOn);
        return;
    }
  }

  async function fetchRecentActivities(params, withFilters = false) {
    try {
      console.log("with filters = ", withFilters);
  
      const response = await getAllActivities(params);
  
      if (response.data.pagination.totalPages <= params.page) {
        // If all pages are loaded or no data is present
        setDisableShowMoreButtonForLeadActivities(true);
      } else {
        // Enable "Show More" button when there are more pages to fetch
        setDisableShowMoreButtonForLeadActivities(false);
      }
  
      if (withFilters) {
        // Replace activities when filters are applied or cleared
        setActivities(response.data.data);
      } else {
        // Append activities when "Show More" is clicked
        setActivities([...activities, ...response.data.data]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchLeadsToday(params) {
    try {
      console.log("fetch leads today api called");

      const response = await getTotalLeadsCount(params);
      setLeadsToday(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTotalLeads(params) {
    try {
      console.log("fetchTotalLeads api called");
      const response = await getTotalLeadsCount(params);
      setTotalLeads(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchRecentTasks(params, withFilters = false) {
    setDisableShowMoreButtonForRecentTasks(false)
    try {
      console.log("with filters = ", withFilters);

      const response = await getAllTasks(params);
      if (
        response.data.pagination.totalPages === 1 ||
        response.data.data.length === 0
      ) {
        if (withFilters) {
          setTasks(response.data.data);
        } else {
          setTasks([...tasks, ...response.data.data]);
        }
        setDisableShowMoreButtonForRecentTasks(true);
      } else {
        if (withFilters) {
          setTasks(response.data.data);
        } else {
          setTasks([...tasks, ...response.data.data]);
        }
        // setTasks([...tasks, ...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchEmployees() {
    try {
      const response = await getUsers();
      setEmployees(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  async function fetchWalkIns(params, withFilters) {
    setDisableShowMoreButtonForWalkIns(false)
    try {
      console.log("with filters = ", withFilters);

      const response = await getWalkIns(params);
      if (
        response.data.pagination.totalPages === 1 ||
        response.data.data.length === 0
      ) {
        if (withFilters) {
          setWalkIns(response.data.data);
        } else {
          setWalkIns([...walkIns, ...response.data.data]);
        }
        setDisableShowMoreButtonForWalkIns(true);
      } else {
        if (withFilters) {
          setWalkIns(response.data.data);
        } else {
          setWalkIns([...walkIns, ...response.data.data]);
        }
        // setTasks([...tasks, ...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchWalkInsCount(params) {
    try {
      const response = await getWalkInsCount(params);
      setWalkinsTotal(response.data.data.totalWalkIns);
      setWalkinsToday(response.data.data.todayWalkIns);
    } catch (error) {
      console.log(error);
    }
  }

  function handleLeadActivitiesFilterChange(e) {
    const { name, value } = e.target;
    const updatedFilters = { ...payloadForRecentActivity, [name]: value, page:1 };
    setPayloadForRecentActivity(updatedFilters);
    debouncedFetchLeadActivities({...updatedFilters, page : 1})
    // fetchRecentActivities(updatedFilters, true);
  }

  return (
    <>
      {role === ROLE_MANAGER ? <ManagerSidebar /> : <AdminSidebar />}
      <div className="global-container">
        <Container fluid>
          <Row
            className="mb-4"
            style={{
              backgroundColor: "white",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            {[
              { title: "Total Leads", count: totalLeads },
              { title: `Leads Today (${todayFormatted})`, count: leadsToday },
              {title: 'Walk Ins'}
            ].map(({ title, count }, index) => (
              <Col md={3} key={index}>
                <Card className="lead-summary-card">
                  <Card.Body>
                    <h1>{title}</h1>
                    {
                      title === "Walk Ins" ?
                      <h4>{`${walkinsToday} / ${walkinsTotal}`}</h4> :
                      <h4>{count}</h4>
                    }
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row
            style={{
              backgroundColor: "white",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <AnalyticsDashboard />
          </Row>

          {/* walk ins */}
          <WalkInsTable
            showMoreClickHandler={(clickedOn) =>
              showMoreClickHandler(clickedOn)
            }
            disableShowMoreButtonForWalkIns={disableShowMoreButtonForWalkIns}
            employees={employees}
            handleWalkInsFilterChange={(e) => handleWalkInsFilterChange(e)}
            userId={null}
            walkIns={walkIns}
            updateTask={() => {}}
            payloadForWalkIns={payloadForWalkIns}
          />

          {/* recent tasks */}
          <RecentTasks
            tasks={tasks}
            showMoreClickHandler={(clickedOn) =>
              showMoreClickHandler(clickedOn)
            }
            disableShowMoreButtonForRecentTasks={
              disableShowMoreButtonForRecentTasks
            }
            userId={null}
            employees={employees}
            handleRecentTasksFilterChange={(e) =>
              handleRecentTasksFilterChange(e)
            }
            updateTask={() => {}}
            payloadForRecentTasks={payloadForRecentTasks}
          />

          {/* recent lead activities */}
          {/* <RecentLeadActivities 
            activities={activities}
            showMoreClickHandler={(clickedOn) =>
              showMoreClickHandler(clickedOn)
            }
            disableShowMoreButtonForLeadActivities={
              disableShowMoreButtonForLeadActivities
            }
            error={error}
            handleLeadActivitiesFilterChange={
              handleLeadActivitiesFilterChange
            }
            payloadForRecentActivity={payloadForRecentActivity}
            setPayloadForRecentActivity={setPayloadForRecentActivity}
            debouncedFetchLeadActivities={debouncedFetchLeadActivities}
          /> */}

          <RecentLeadActivityLogs users={users}/>

        </Container>
      </div>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
    </>
  );
};

export default AdminDashboard;
