import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import CommonSnackbar from "./common/CommonSnackbar";
import { deleteLoanReport } from "../services/loan-reports-services";
import { useAuth } from "../context/AuthContext";
import { ROLE_EMPLOYEE } from "../constants/AppConstants";
import { deleteCreditReport } from "../services/credit-reports-services";

const SummaryTable = React.memo(function SummaryTable({
  loanReportsSummary,
  creditReportsSummary,
  downloadReport,
  lead,
  userId,
  setLoanReportsSummary,
  setCreditReportsSummary,
}) {
  const { user } = useAuth();
  // Snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  async function deleteLoanReportHandler(loanId,loan) {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this loan report ?"
    );

    if (!confirmDelete) {
      return;
    }

    try {
      // Call the deleteLoanReport API function
      let payload = {};
      payload.id = loanId;
      payload.updated_by = userId;
      payload.loan = loan
      payload.lead_name = lead.name
      const response = await deleteLoanReport(payload);

      if (response.status === 200) {
        console.log("API Response:", response.data);

        // Assuming you have a method to update the state after deletion
        setLoanReportsSummary((prev) =>
          prev.filter((loan) => loan.id !== loanId)
        );
        setSnackbarMessage("Loan report deleted successfully!");
        setSnackbarType("success");
      } else {
        throw new Error(
          response.data.message || "Failed to delete loan report."
        );
      }
    } catch (error) {
      console.error("Error deleting loan data:", error);

      setSnackbarMessage(
        error.response?.data?.message || "Failed to delete loan report."
      );
      setSnackbarType("error");
    } finally {
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 2000);
    }
  }

  async function deleteCreditReportHandler(creditId,creditReport) {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this credit report ?"
    );

    if (!confirmDelete) {
      return;
    }

    try {
      // Call the deleteLoanReport API function
      let payload = {};
      payload.id = creditId;
      payload.updated_by = userId;
      payload.credit_report = creditReport
      const response = await deleteCreditReport(payload);

      if (response.status === 200) {
        console.log("API Response:", response.data);

        // Assuming you have a method to update the state after deletion
        setCreditReportsSummary((prev) =>
          prev.filter((credit) => credit.id !== creditId)
        );
        setSnackbarMessage("Credit report deleted successfully!");
        setSnackbarType("success");
      } else {
        throw new Error(
          response.data.message || "Failed to delete credit report."
        );
      }
    } catch (error) {
      console.error("Error deleting credit data:", error);

      setSnackbarMessage(
        error.response?.data?.message || "Failed to delete credit report."
      );
      setSnackbarType("error");
    } finally {
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 2000);
    }
  }

  return (
    <>
      <Card className="p-3">
        <h4>Summary</h4>
        <div className="row">
          {/* LOAN REPORTS TABLE */}
          <div className="col-sm-12">
            <p>
              <strong>Loan Report</strong>
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Loan Type</th>
                  <th>Bank Name</th>
                  <th>Loan Amount</th>
                  <th>EMI</th>
                  <th>Outstanding</th>
                  {user.user.role !== ROLE_EMPLOYEE && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {loanReportsSummary.map((loan) => (
                  <tr key={loan.id}>
                    <td>{loan.loan_type}</td>
                    <td>{loan.bank_name}</td>
                    <td>{loan.loan_amount}</td>
                    <td>{loan.emi}</td>
                    <td>{loan.outstanding}</td>
                    {user.user.role !== ROLE_EMPLOYEE && (
                      <td style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          disabled={!loan.id}
                          onClick={() => deleteLoanReportHandler(loan.id,loan)}
                        >
                          Delete
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>
                      {loanReportsSummary.reduce(
                        (total, loan) =>
                          Number(total) + Number(loan.loan_amount),
                        0
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {loanReportsSummary.reduce(
                        (total, loan) => Number(total) + Number(loan.emi),
                        0
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {loanReportsSummary.reduce(
                        (total, loan) =>
                          Number(total) + Number(loan.outstanding),
                        0
                      )}
                    </strong>
                  </td>
                  {user.user.role !== ROLE_EMPLOYEE && <td></td>}
                </tr>
              </tfoot>
            </table>
          </div>

          {/* CREDIT REPORTS TABLE */}
          <div className="col-sm-6">
            <p>
              <strong>Credit Reports</strong>
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Credit Card Name</th>
                  <th>Total Outstanding</th>
                  {user.user.role !== ROLE_EMPLOYEE && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {creditReportsSummary.map((credit) => (
                  <tr key={credit.id}>
                    <td>{credit.credit_card_name}</td>
                    <td>{credit.total_outstanding}</td>
                    {user.user.role !== ROLE_EMPLOYEE && (
                      <td style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          disabled={!credit.id}
                          onClick={() => deleteCreditReportHandler(credit.id, credit)}
                        >
                          Delete
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>
                      {creditReportsSummary.reduce(
                        (total, credit) =>
                          Number(total) + Number(credit.total_outstanding),
                        0
                      )}
                    </strong>
                  </td>
                  {user.user.role !== ROLE_EMPLOYEE && <td></td>}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div className="col-sm-12">
          <h6>
            <strong>Download Reports</strong>
          </h6>
          <Button
            style={{
              backgroundColor: "blue",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={()=>downloadReport(
              'pdf',
              loanReportsSummary,
              creditReportsSummary,
              lead.name,
              lead.id
            )}
          >
            Download As PDF
          </Button>

          <Button
            style={{
              backgroundColor: "green",
              color: "white",
              fontWeight: "bold",
              marginLeft: "1rem",
            }}
            onClick={()=>downloadReport(
              'excel',
              loanReportsSummary,
              creditReportsSummary,
              lead.name,
              lead.id
            )}
          >
            Download As Excel
          </Button>
        </div>
      </Card>
      {/* Snackbar */}
      {openSnackbar && (
        <CommonSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          type={snackbarType}
        />
      )}
    </>
  );
});

export default SummaryTable;
