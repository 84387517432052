import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Typography, Divider, Box, Grid2 } from "@mui/material";
import { Button, Card} from "react-bootstrap";
import { getAllActivityLogs } from "../services/activity-log-services";
import { getLeadById } from "../services/admin/leads-services";
import { useNavigate, useLocation } from "react-router-dom";
import AddActivityDialogue from "./employee/leads/AddActivityDialogue";
import { useAuth } from "../context/AuthContext";
import { ROLE_EMPLOYEE } from "../constants/AppConstants";
import NoteDialogue from "./NoteDialogue";

// Function to group activities by date
const groupActivitiesByDate = (activities) => {
  return activities.reduce((acc, activity) => {
    const date = moment(activity.createdAt)
      .utcOffset(330)
      .format("DD MMM YYYY"); // Format: 01 Feb 2025
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(activity);
    return acc;
  }, {});
};

const LeadActivityTimeline = ({ users, leadName, callApi=false, leadIdName, filters={}, leadId, lead=null }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {user} = useAuth()
  const [activityLogs, setActivityLogs] = useState([])
  const [pagination,setPagination] = useState({ page: 1, pageSize: 10, totalPages: 1 })
  const [disableSeeMoreButton, setDisableSeeMoreButton] = useState(false)
  const [showActivityDialogue, setShowActivityDialogue] = useState(false);

  // note dialogue states
  const [showNoteDialogue, setShowNoteDialogue] = useState(false)
  const [payloadForNoteDialogue, setPayloadForNoteDialogue] = useState(null)

  useEffect(()=>{
    setPayloadForNoteDialogue({
      lead_id : leadIdName.leadId,
      user_id: user?.user.id,
      lead_name: leadIdName.leadName
    })
  },[])

  const userMap = useMemo(() => {
    return new Map(users.map((user) => [user.id, user.name]));
  }, [users]);
  
  useEffect(()=>{
    if(callApi){
        fetchActivityLogs({ lead_id: leadId, page:1, pageSize:10, ...filters })
    }
  },[filters])

  const groupedActivities = groupActivitiesByDate(activityLogs);

  async function fetchActivityLogs(payload,append = false){
    try {
        const response = await getAllActivityLogs(payload)
        console.log('response from logs = ', response.data.pagination);
        setActivityLogs(prevLogs =>
            append ? [...prevLogs, ...response.data.data] : response.data.data
          );
          setPagination((prevPagination)=>({
            ...prevPagination,
            page:Number(response.data.pagination.page),
            totalPages:response.data.pagination.totalPages
          }));
    
          // Disable "See More" button if there are no more pages left
          setDisableSeeMoreButton(Number(response.data.pagination.page) >= response.data.pagination.totalPages);
    } catch (error) {
        console.log('error = ', error);
    }
  }

  async function handleSeeMoreClick() {
      const nextPage = pagination.page + 1;

      setPagination(prevPagination => ({
        ...prevPagination,
        page: nextPage
      }));

      fetchActivityLogs({ lead_id: leadIdName?.leadId || leadId, page: nextPage, pageSize: pagination.pageSize, ...filters }, true);
  }

  async function goToLeadDetailsPage(lead_id) {
    try {
    if(location.pathname.includes('/admin-dashboard')){
    let includeFields = ["id", "lead_status", "verification_status", "application_status"];
    const response = await getLeadById(lead_id, { includeFields: includeFields.join(",") });
    let lead = response.data.data;
    navigate(`/lead-details-emp/${lead.id}`, {
      state: {
        showVerificationStatusChangeSection: lead.lead_status === "Verification 1",
        showApplicationStatusChangeSection: 
          ["Scheduled For Walk-In", "Scheduled Call With Manager"].includes(lead.lead_status) &&
          ["Scheduled For Walk-In", "Scheduled Call With Manager"].includes(lead.verification_status),
        showLeadStatusChangeSection: 
          !["Scheduled For Walk-In", "Scheduled Call With Manager"].includes(lead.lead_status) &&
          !["Scheduled For Walk-In", "Scheduled Call With Manager"].includes(lead.verification_status),
        leadBucket: lead.lead_status === "Verification 1"
          ? "Verification 1"
          : ["Scheduled For Walk-In", "Scheduled Call With Manager"].includes(lead.verification_status)
          ? "WalkIns"
          : "Leads",
        fromPage: 'admin-dashboard',
        filters,
        pagination
      }
    });
  }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
    <Card className="p-3">
      {
        user?.user.role === ROLE_EMPLOYEE &&
        <div style={{position:'absolute', right:10, display:'flex'}}>
          <Button style={{marginRight:'0.5rem'}} onClick={()=>setShowActivityDialogue(true)}>Create Task</Button>
          <Button onClick={()=>setShowNoteDialogue(true)}>Add Note</Button>
        </div>
      }
      {Object.keys(groupedActivities).map((date, dateIndex) => (
        <Box key={dateIndex} sx={{ mb: 0}}>
          {/* Date Header */}
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            color="secondary"
            gutterBottom
          >
            {date}
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {/* Activity List */}
          {groupedActivities[date].map((activity, index) => (
            <Grid2
              key={index}
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                mb: 2,
              }}
            >
              {/* Timestamp Container */}
              <Grid2
                sx={{
                    width: "15%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 1,
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "3rem", // Adjust width to control curve size
                      height: "1rem",
                      borderTop: "1px solid gray",
                      borderRight: "1px solid gray",
                      borderTopRightRadius: "10px", // Smooth curve on top
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                    //   bottom: "-1px",
                      right: 0,
                      width: "0.05rem", // Single vertical divider
                      height: "calc(100% - 1rem)", // Prevents overlapping with the curve
                      backgroundColor: "gray",
                    },
                  }}
              >
                <Typography
                  variant="body2"
                  style={{
                    color: "#5691FF",
                    backgroundColor:'rgb(192 212 249)',
                    opacity: 2,
                    marginBottom:'0.5rem',
                    padding: "0.25rem 0.75rem",
                    borderRadius: "9999px", // Ensures a capsule shape
                    display: "inline-block", // Prevents full width stretching
                    textAlign: "center",
                  }}
                >
                  {moment(activity.createdAt)
                    .utcOffset(330)
                    .format("hh:mm A")}
                </Typography>

                <Typography 
                    variant="caption"
                    style={{
                        color: "black",
                        border: '1px solid black',
                        // backgroundColor:'rgb(192 212 249)',
                        opacity: 2,
                        // border: "1px solid white",
                        padding: "0.25rem 0.75rem",
                        borderRadius: "9999px", // Ensures a capsule shape
                        display: "inline-block", // Prevents full width stretching
                        textAlign: "center",
                        fontSize:'0.5rem'
                    }}
                >
                  {userMap.get(activity.created_by) || "Unknown"}
                </Typography>
              </Grid2>

              {/* Details Container */}
              <Grid2
                sx={{
                    width: "85%",
                    display: "flex",
                    flexDirection: "column",
                    p: 1,
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                    //   width: "1px", // Continue vertical divider
                      height: "calc(100% - 1rem)", // Avoids overlapping with the bottom curve
                    //   backgroundColor: "gray",
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: '-0.05rem',
                      width: "3rem",
                      height: "1rem",
                      borderBottom: "1px solid gray",
                      borderLeft: "1px solid gray",
                      borderBottomLeftRadius: "10px", // Smooth curve on bottom
                    },
                  }}
              >
                <Typography 
                    variant="body1" 
                    // fontWeight="bold"
                    style={{
                        color: "black",
                        backgroundColor:'yellow',
                        opacity: 2,
                        // border: "1px solid white",
                        padding: "0.15rem 0.75rem",
                        borderRadius: "9999px", // Ensures a capsule shape
                        display: "inline-block", // Prevents full width stretching
                        textAlign: "center",
                        width:'max-content',
                        fontSize: '0.5rem',
                        fontWeight:'bold',
                        cursor: 'pointer'
                    }}
                    onClick={()=> goToLeadDetailsPage(activity.lead_id)}
                >
                  {activity.lead_name || leadIdName?.name || "Lead Name"}
                </Typography>
                <Typography variant="body2">
                  {activity.activity_desc}
                </Typography>
                {activity.note && (
                  <Typography variant="caption"
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '18px',
                      letterSpacing: '0%',
                      backgroundColor:'#5691FF2B',
                      border:'1px solid #6D0085',
                      padding:'0.2rem 0.4rem',
                      borderRadius:'10px',
                      width:'max-content'
                    }}    
                  >
                    {activity.note}
                  </Typography>
                )}
              </Grid2>
            </Grid2>
          ))}
        </Box>        
      ))}
      <div style={{display:"flex", justifyContent:'flex-end', marginTop:'0.5rem'}}>
        <Button onClick={()=>handleSeeMoreClick()} disabled={disableSeeMoreButton}>See More...</Button>
      </div>
    </Card>
    {showActivityDialogue && (
          <AddActivityDialogue
            open={showActivityDialogue}
            handleClose={() => setShowActivityDialogue(false)}
            lead={{id:leadIdName.leadId, name: leadIdName.name}}
            updateLeadStatus={()=>{}}
            forActivityLogsPage={true}
          />
    )}
    {
      showNoteDialogue && (
        <NoteDialogue
          open={showNoteDialogue}
          handleClose={() => setShowNoteDialogue(false)}
          payload={payloadForNoteDialogue}
        />
      )
    }
    </>
  );
};

export default LeadActivityTimeline;
