import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import { addActivityLogNote } from "../services/activity-log-services";

// Create custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    maxWidth: "none", // Removes maxWidth
    maxHeight: "none", // Removes maxHeight
  },
  dialogContent: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  dialogActions: {
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  secondaryButton: {
    border: "1px solid black",
    color: "black",
    "&:hover": {
      backgroundColor: "gray",
      color: "white",
    },
  },
  primaryButton: {
    border: "1px solid black",
    color: "white",
    backgroundColor: "#fd7e14",
    "&:hover": {
      backgroundColor: "#fd7e14",
      color: "white",
    },
  },
}));

const NoteDialogue = React.memo(function NoteDialogue({
  open,
  handleClose,
  payload,
}) {
  const classes = useStyles();
  const [note, setNote] = useState("");
  const [
    shouldNoteDialogueCloseOnClickOfOutside,
    setShouldNoteDialogueCloseOnClickOfOutside,
  ] = useState(true);
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [payloadForApi, setPayloadForApi] = useState(payload);
  const [apiResponseType, setApiResponseType] = useState('')
  const [showHelperMessage, setShowHelperMessage] = useState(false)
  const [helperMessage, setHelperMessage] = useState('')

  async function handleUpdate(payload) {
    try {
      setDisableSubmitButton(true)
      setShouldNoteDialogueCloseOnClickOfOutside(false)
      setHelperMessage('Adding Note ...')
      setShowHelperMessage(true)
      setApiResponseType('inProgress')
      const response = await addActivityLogNote(payload);
      setApiResponseType('success')
      setHelperMessage(response.data.message)
      setShouldNoteDialogueCloseOnClickOfOutside(true)
      console.log("response from api add activity log = ", response.data);
    } catch (error) {
      setApiResponseType('error')
      setHelperMessage('Failed to add note !')
      setShouldNoteDialogueCloseOnClickOfOutside(true)
      console.log(error);
    }
  }

  function resetStates() {}

  function handleOnChangeNote(e) {
    setNote(e.target.value);
    setDisableSubmitButton(e.target.value.trim().length === 0);
    setPayloadForApi((prevPayload) => ({
      ...prevPayload,
      note: e.target.value,
    }));
  }

  return (
    <Dialog
      onClose={shouldNoteDialogueCloseOnClickOfOutside && handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: classes.dialogRoot }}
    >
      <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
        Add Note
        <IconButton
          aria-label="close"
          onClick={
            shouldNoteDialogueCloseOnClickOfOutside ? handleClose : () => {}
          }
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {
            showHelperMessage ? 
                <>
              {/* helper message UI code */}
              <Grid2
                style={{
                  minHeight: "50vh",
                  minWidth: "50vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {apiResponseType == "success" ? (
                  <TaskAltIcon style={{ color: "green" }} fontSize="large" />
                ) : apiResponseType == "error" ? (
                  <ErrorOutlineIcon style={{ color: "red" }} fontSize="large" />
                ) : (
                  <CircularProgress style={{ color: "rgb(127, 12, 134)" }} />
                )}
                {helperMessage}
              </Grid2>
            </> 
            :<>
                {/* content container */}
        <Grid2
          style={{
            height: "max-content",
            width: "50vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxHeight: "75vh",
          }}
        >
          <Row className="align-items-center">
            <Col xs={12} style={{ padding: "0rem 0.2rem" }}>
              <Form.Group controlId="rejectionReason">
                <Form.Control
                  as="textarea"
                  rows={10}
                  name="note"
                  value={note}
                  onChange={(e) => handleOnChangeNote(e)}
                  placeholder="Enter the note here..."
                />
              </Form.Group>
            </Col>
          </Row>
        </Grid2>
            </>
        }
        
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <>
          <Button
            autoFocus
            onClick={() => {
              resetStates();
              handleClose();
            }}
            className={classes.secondaryButton}
            disabled={disableCancelButton}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={() => handleUpdate(payloadForApi)}
            className={classes.primaryButton}
            disabled={disableSubmitButton}
          >
            Add
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
});

export default NoteDialogue;
