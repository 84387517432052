import moment from "moment";
import React from "react";
import { Card, Table } from "react-bootstrap";

const UpdateInfoTable = React.memo(function UpdateInfoTable({ users, logs=[] }) {
  return (
    <Card className="p-3 mt-3 pb-0">
        <h4>Update History</h4>
      <Table striped>
        <thead>
          <tr>
            <th>Updated By</th>
            <th>Updated At</th>
            <th>Note</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {logs?.map((log) => (
            <tr>
              <td style={{ width: "20%" }}>{users.find((user) => user.id === log.created_by)?.name}</td>
              <td style={{ width: "15%" }}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  {moment(log.createdAt)
                    .utcOffset(330)
                    .format("DD MMM, YYYY hh:mm:ss A")}
                </span>
              </td>
              <td style={{ width: "30%" }}>{log.note}</td>
              <td style={{ width: "35%" }}>{log.activity_desc}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
});

export default UpdateInfoTable;
