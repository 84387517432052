import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Col, Row, Form, Table } from "react-bootstrap";
import { getActivitiesByLeadId } from "../services/admin/tasks-services";
import moment from "moment";
import LeadActivityTimeline from "./LeadActivityTimeline";

// Create custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    maxWidth: "none !important", // Removes maxWidth
    maxHeight: "none !important", // Removes maxHeight
    // padding: '0px !important'
  },
  dialogContent: {
    padding: '0px !important',
    display: "flex !important",
    flexDirection: "column !important",
  },
  dialogActions: {
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute !important",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "baseline !important",
  },
  secondaryButton: {
    border: "1px solid black !important",
    color: "black !important",
    "&:hover": {
      backgroundColor: "gray !important",
      color: "white !important",
    },
  },
  primaryButton: {
    border: "1px solid black !important",
    color: "white !important",
    backgroundColor: "#fd7e14 !important",
    "&:hover": {
      backgroundColor: "#fd7e14 !important",
      color: "white !important",
    },
  },
}));

const LeadActivitiesDialogue = React.memo(function LeadActivitiesDialogue({
  open,
  handleClose,
  leadIdName,
  users,
  leadId
}) {
  const classes = useStyles();
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    fetchActivitiesByLeadId(leadIdName.leadId);
  }, []);

  async function fetchActivitiesByLeadId(leadId) {
    try {
      const response = await getActivitiesByLeadId(leadId);
      setActivities(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.dialogTitle}
        >
          Lead Activities
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          {/* content container */}
          <Grid2
            style={{
              height: "max-content",
              width: "65vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              maxHeight: "75vh",
              minHeight: "65vh",
              overflowY: "auto",
              border: '1px solid gray',
              // borderRadius: '0.5rem'
            }}
          >
            <LeadActivityTimeline callApi={true} leadIdName={leadIdName} users={users} leadId={leadId} filters={{}}/>
          </Grid2>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            autoFocus
            onClick={handleClose}
            className={classes.secondaryButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default LeadActivitiesDialogue;
