import React, { useCallback, useState } from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import LeadActivityTimeline from "./LeadActivityTimeline";
import DateTimeRangeFilter from "./common/DateTimeRangeFilter";
import { debounce, exportLeadActivityLogs } from "../utilities/helper-functions";
import CommonSnackbar from "./common/CommonSnackbar";

const RecentLeadActivityLogs = React.memo(function RecentLeadActivityLogs({
  users,
}) {
  const [filters, setFilters] = useState({});
  // snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const [
    shouldSnackbarCloseOnClickOutside,
    setShouldSnackbarCloseOnClickOutside,
  ] = useState(true);
  const debouncedSetFilters = useCallback(
    debounce((newFilters) => {
      setFilters(newFilters);
    }, 100),
    []
  );

  function handleFiltersChange(e) {
    const { name, value } = e.target;
    debouncedSetFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  }

  function handleDateRangeChange(
    startDateTime,
    endDateTime,
    filterName,
    shouldRemoveFilter
  ) {
    let updatedFilters = { ...filters };

    if (filterName) {
      if (shouldRemoveFilter) {
        console.log("Removing filter:", filterName);
        delete updatedFilters[filterName]; // Use `updatedFilters` instead of `payloadForRecentActivity`
      } else {
        updatedFilters = {
          ...filters,
          [filterName]: `${startDateTime},${endDateTime}`,
        };
      }
    }

    // Remove the `filterName` key completely if `shouldRemoveFilter` is true
    const finalPayload = shouldRemoveFilter
      ? { ...updatedFilters }
      : {
          ...updatedFilters,
          [filterName]: `${startDateTime},${endDateTime}`,
        };

    setFilters(finalPayload);
  }

  async function handleExportLeads(){
    setSnackbarMessage(`Exporting Leads... 0%`)
    setSnackbarType('info')
    setOpenSnackbar(true)
    setShouldSnackbarCloseOnClickOutside(false)
    let response = null
    response = await exportLeadActivityLogs(users, filters, setSnackbarMessage)
    if(response === true){
      setSnackbarType('success')
      setSnackbarMessage('Leads Exported Successfully')
      setTimeout(()=>setOpenSnackbar(false), 1500)
      setShouldSnackbarCloseOnClickOutside(true)
    }else if(response === false && response !== null){
      setSnackbarType('error')
      setSnackbarMessage('Failed to export leads !')
      setTimeout(()=>setOpenSnackbar(false), 1500)
      setShouldSnackbarCloseOnClickOutside(true)
    }
  }

  return (
    <>
      <Row
        style={{
          marginTop: "1rem",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <Col md={12}>
          <Card.Body>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col md={2}>
                <h1>Recent Lead Activities</h1>
              </Col>
              <Col md={2}>
                <input
                  type="text"
                  name="lead_id"
                  className="form-control"
                  placeholder="Filter by Lead ID"
                  value={filters.lead_id || ""}
                  onChange={(e) => handleFiltersChange(e)}
                />
              </Col>
              <Col
                md={2}
                style={{ position: "relative", marginBottom: "1.5rem" }}
              >
                <label
                  htmlFor="createdAt"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Created On
                </label>
                <DateTimeRangeFilter
                  onDateRangeChange={handleDateRangeChange}
                  name="createdAt"
                  filters={filters}
                />
              </Col>

              <Col md={3}>
                <select
                  name="created_by"
                  className="form-control me-2"
                  onChange={(e) => handleFiltersChange(e)}
                  defaultValue=""
                  value={filters.created_by || ""}
                  style={{ cursor: "pointer" }}
                >
                  <option value="">Filter by Employee Name</option>
                  {users.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </Col>
              {/* {error && <div className="error-message">{error}</div>} */}
              <div
                className="col-md-3 position-relative"
                style={{
                  placeContent: "center",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", color: "white" }}
                  onClick={() => handleExportLeads()}
                >
                  Export Records
                </Button>
                <Button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "1rem",
                  }}
                  onClick={()=>{
                    let payload = {}
                    setFilters(payload)
                  }}
                >
                  Reset Filters
                </Button>
              </div>
            </Row>
            <LeadActivityTimeline
              callApi={true}
              users={users}
              filters={filters}
              leadIdName={{}}
            />
          </Card.Body>
        </Col>
      </Row>
      {openSnackbar && (
                <CommonSnackbar
                  open={openSnackbar}
                  onClose={() => setOpenSnackbar(false)}
                  message={snackbarMessage}
                  type={snackbarType}
                  shouldSnackbarCloseOnClickOutside={shouldSnackbarCloseOnClickOutside}
                />
      )}
    </>
  );
});

export default RecentLeadActivityLogs;
